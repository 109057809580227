// prettier-ignore

// *****
// Add a key for each translated string.
// Use dot notation to structure the strings better.
// Start the key with the page or component name.
// Please use logical and understandable keys.
// Please check to see if a translattion is already done before adding a new one.
// *****

export default {
  // Globals
  'globals.locale': 'sk',
  'globals.signIn': 'Prihlásiť sa',
  'globals.logIn': 'Prihlásiť sa',
  'globals.signUp': 'Zaregistrovať sa',
  'globals.signOut': 'Odhlásiť sa',
  'globals.logOut': 'Odhlásiť sa',
  'globals.submit': 'Odoslať',
  'globals.submitting': 'Odosiela sa...',
  'globals.yourEmail': 'Váš e-mail',
  'globals.yourPassword': 'Vaše heslo',
  'globals.courseOverview': 'Prehľad kurzu',
  'globals.forgotPassword': 'Zabudli ste heslo?',
  'globals.invalidCredentials': 'Neplatné prihlasovacie údaje',
  'globals.section': 'Oddiel',
  'globals.exercises': 'Cvičenia',
  'globals.chapter': 'Kapitola',
  'globals.introToAI': 'Úvod do umelej inteligencie',
  'globals.buildingAI': 'Budovanie AI',
  'globals.acceptPolicy': 'Prijmite politiku',
  // section
  'section.footerEndOfTheChapter': 'Dostali ste sa na koniec kapitoly',
  'section.footerEndOfTheCourseTitle': 'Dostali ste sa na koniec kurzu.',
  'section.footerEndOfTheCourseText': 'Po dokončení všetkých cvičení a hodnotení vaše odpovede ohodnotia naši lektori.',
  'section.footerEndOfTheCourseBAI': 'Pokračujte na ceste k schopnosti implementovať svoj prvý nápad s AI.',
  'section.nextSection': 'Ďalší oddiel',
  'section.nextChapter': 'Ďalšia kapitola',
  'section.start': 'Začiatok',
  'section.logisticRegressionExample.slope': 'Sklon',
  'section.logisticRegressionExample.intercept': 'Intercept',
  // quiz peer review
  'quiz.awaitingPeerReview': 'Čaká sa, kým vypracujete hodnotenie',
  'quiz.rejectedPeerReview': 'Zamietnuté pri hodnotení',
  'quiz.unsubmittedAnswer': 'Odpoveď neodoslaná',
  'quiz.pendingPeerReview': 'Čaká sa na hodnotenie',
  // quiz
  'quiz.status.answered': 'Zodpovedané',
  'quiz.status.rejected': 'Odpoveď zamietnutá, skúste to znova',
  'quiz.status.unanswered': 'Nezodpovedané',
  'quiz.status.exercisesCompleted': 'Ukončené cvičenia',
  'quiz.status.correctAnswers': 'Správne odpovede',
  'quiz.message.rejectedInReview': 'Vaša predchádzajúca odpoveď bola pri hodnotení zamietnutá. Skúste to znova.',
  'quiz.youAnswered': 'Odpovedali ste',
  'quiz.yourAnswer': 'Vaša odpoveď...',
  'quiz.yourScore': 'Vaše skóre:',
  'quiz.quizNotLoaded': 'Nebolo možné nahrať kvíz',
  'quiz.words': 'Počet slov:',
  'quiz.exampleAnswer': 'Vzorová odpoveď:',
  // peer review
  'peerreview.question1': 'Odpoveď je k téme',
  'peerreview.question2': 'Odpoveď je komplexná',
  'peerreview.question3': 'Odpoveď je dobre odôvodnená.',
  'peerreview.question4': 'Odpoveď je zrozumiteľná',
  'peerreview.thankYou': 'Ďakujeme, že ste ohodnotili odpovede iných účastníkov!',
  'peerreview.giveMoreReviews': 'Ak máte čas,',
  'peerreview.giveMoreReviewsButton': 'vypracujte viac hodnotení',
  'peerreview.notEnoughAnswers': 'Tu by malo byť hodnotenie, ale toto cvičenie nevypracovalo dosť iných účastníkov.',
  'peerreview.selectAnswerMessage': 'Je čas vypracovať hodnotenie! Nižšie uvádzame niekoľko odpovedí iných účastníkov. Vyberte si jednu z nich a ohodnoťte ju. Vypracujte dokopy aspoň tri hodnotenia.',
  'peerreview.minimumPeerReviewWarning': 'Cvičenie možno prijať, až keď odovzdáte aspoň tri hodnotenia.',
  'peerreview.select': 'Vybrať',
  'peerreview.reportSpam': 'Nahlásiť spam',
  'peerreview.feedback': 'Vaša odpoveď bola ohodnotená a prijatá. Tu sú názory ostatných účastníkov:',
  // exercise17
  'quiz.exercise17.xLabel': 'Počet rokov vzdelávania',
  'quiz.exercise17.yLabel': 'Očakávaná dĺžka života v rokoch',
  'quiz.exercise17.textPart1': 'Pozrime sa na súvislosť medzi celkovým počtom rokov strávených v škole (pri zahrnutí všetkých rokov medzi predškolským a vysokoškolským vzdelávaním) a očakávanou dĺžkou života. Tu sú údaje z troch rôznych krajín, ktoré sú na obrázku zobrazené bodkami:',
  'quiz.exercise17.textPart2': 'V jednej krajine je priemerný počet rokov strávených v škole 10 a očakávaná dĺžka života je 57 rokov, v druhej krajine je priemerný počet rokov strávených v škole 13 a očakávaná dĺžka života je 53 rokov a v tretej krajine je priemerný počet rokov strávených v škole 20 a očakávaná dĺžka života je 80 rokov.',
  'quiz.exercise17.textPart3': 'Koncové body plnej čiary môžete potiahnuť tak, aby čiara sledovala trend dátových bodov. Všimnite si, že nebudete môcť čiaru dokonale napasovať na dátové body, ale to nevadí: niektoré z dátových bodov sa budú nachádzať nad touto čiarou a niektoré z nich pod ňou. Najdôležitejšie je, že čiara opisuje celkový trend.',
  'quiz.exercise17.textPart4': 'Po umiestnení tejto čiary ju môžete použiť na predpovedanie očakávanej dĺžky života.',
  'quiz.exercise17.textPart5': 'Čo môžete na základe údajov povedať o očakávanej dĺžke života ľudí, ktorí navštevovali školu 15 rokov? Dôležité upozornenie: Všimnite si, že aj keď upravením čiary môžete dospieť ku konkrétnej predpovedi na zlomok roka presne, táto predpoveď nemusí byť nutne spoľahlivá. Vo svojej odpovedi zohľadnite obmedzené množstvo údajov.',
  // exercise18
  'quiz.exercise18.xLabel': 'Počet rokov vzdelávania',
  'quiz.exercise18.yLabel': 'Očakávaná dĺžka života v rokoch',
  'quiz.exercise18.textPart1': 'V predchádzajúcom cvičení sme mali údaje len z troch krajín. Celý súbor údajov pozostáva z údajov zo 14 rôznych krajín, ktoré sa uvedené v grafe:',
  'quiz.exercise18.textPart2': 'Zmenila by sa na základe týchto údajov vaša predpoveď o očakávanej dĺžke života ľudí, ktorí školu navštevovali 15 rokov? Ak áno, prečo?',
  'quiz.exercise18.textPart3': 'Ktorá z nasledujúcich možností by najlepšie zodpovedala vášmu odhadu očakávanej dĺžky života ľudí, ktorí školu navštevovali 15 rokov? Vyberte najkonkrétnejšiu možnosť, ktorú považujete za odôvodnenú uplatnením modelu rovnej čiary na uvedené údaje.',
  // exercise19
  'quiz.exercise19.xLabel': 'Počet hodín štúdia',
  'quiz.exercise19.yLabel': 'Pravdepodobnosť úspešnosti',
  'quiz.exercise19.textPart1': 'Každá bodka v grafe zodpovedá jednému študentovi. V spodnej časti grafu máme stupnicu s počtom hodín, ktoré študent strávil prípravou na skúšku. Študenti, ktorí skúšku zložili, sú zobrazení ako bodky v hornej časti grafu a neúspešní študenti sú zobrazení v spodnej časti. Stupnicu vľavo použijeme na určenie predpokladanej pravdepodobnosti úspechu, ktorú získame na základe modelu logistickej regresie, ako vysvetľujeme ďalej. Na základe tohto grafu môžete zhruba vidieť, že študenti, ktorí sa pripravovali dlhšie, mali väčšiu šancu úspešne absolvovať kurz. Najmä extrémne prípady sú intuitívne: ak študent príprave venuje menej ako hodinu, je veľmi ťažké kurz absolvovať, ale po poctivej príprave väčšina študentov uspeje. Ale čo s tými, ktorých čas venovaný na štúdium patrí niekde medzi tieto extrémy? Ak budete študovať 6 hodín, aké sú vaše šance na úspech?',
  'quiz.exercise19.textPart2': 'Pravdepodobnosť úspešnosti môžeme vyčísliť použitím logistickej regresie. Krivku v grafe možno interpretovať ako pravdepodobnosť úspešnosti: napríklad po približne 5-hodinovej príprave je pravdepodobnosť úspešnosti o niečo vyššia ako 20 %. Nebudeme zachádzať do podrobností o tom, ako získať túto krivku, ale bude to podobné ako pri zisťovaní váh v lineárnej regresii.',
  'quiz.exercise19.textPart3': 'Ak chcete mať 80 % šancu na úspešné absolvovanie skúšky na vysokej škole, približne koľko hodín by ste na základe uvedeného grafu mali venovať príprave?',
  'quiz.exercise19.textPart4': ' ',
  // exercise22
  'quiz.exercise22.sigmoidXLabel': 'Vstup',
  'quiz.exercise22.sigmoidYLabel': 'Sigmoidálny výstup',
  'quiz.exercise22.stepXLabel': 'Vstup',
  'quiz.exercise22.stepYLabel': 'Výstup krokovej funkcie',
  'quiz.exercise22.identityXLabel': 'Vstup',
  'quiz.exercise22.identityYLabel': 'Výstup funkcie identity',
  'quiz.exercise22.textPart1': 'Ďalej sú uvedené grafy pre tri rôzne aktivačné funkcie s rôznymi vlastnosťami. Najprv máme sigmoidálnu funkciu, potom krokovú funkciu a nakoniec funkciu identity.',
  // exercise23
  'quiz.exercise23.happyFaces': 'správne zatriedené šťastné tváričky',
  'quiz.exercise23.sadFaces': 'správne zatriedené smutné tváričky',
  // certificate availability
  'certificate.failedToCheck': 'Nepodarilo sa skontrolovať dostupnosť certifikátu',
  'certificate.failedToGenerate': 'Nepodarilo sa vytvoriť certifikát',
  'certificate.notYetAvailable': 'Po našom potvrdení bude váš certifikát k dispozícii na tomto mieste. Môže to chvíľu trvať. Ďakujeme vám za trpezlivosť.',
  'certificate.updateName': 'Váš certifikát je k dispozícii! Svoje meno a priezvisko si môžete aktualizovať pomocou formulára, ktorý je k dispozícii na stránke vášho profilu.',
  'certificate.generate': 'Certifikát si môžete vytvoriť nižšie.',
  'certificate.generateTextPart1': 'Certifikát sa vygeneruje pre',
  'certificate.generateTextPart2': 'Ešte pred vygenerovaním certifikátu sa uistite, že údaje sú správne, lebo meno už potom nebude možné zmeniť. Meno si môžete zmeniť vo formulári nižšie.',
  'certificate.generateButton': 'Vytvoriť certifikát',
  'certificate.downloadButton': 'Stiahnuť certifikát',
  'certificate.checkingAvailability': 'Kontrola dostupnosti certifikátu...',
  // Visualizations
  'visualizations.logisticRegressionChart.classifiedCorrectly': 'Správne zatriedené',
  'visualizations.logisticRegressionChart.notClassifiedCorrectly': 'Nesprávne zatriedené',
  // section blocks
  'section.block.note': 'Poznámka',
  'section.block.example': 'Príklad',
  'section.block.keyTerminology': 'Kľúčové pojmy',
  'section.partSummary.spectrumTextPart1': 'Zapojte sa do ',
  'section.partSummary.spectrum': 'komunity Elements of AI',
  'section.partSummary.spectrumTextPart2': '. Tam môžete diskutovať a klásť otázky o tejto kapitole.',
  'section.partSummary.feedbackTitle': 'Napíšte nám, čo si myslíte o tejto časti',
  'section.partSummary.feedbackPlaceholder': 'Tu nám môžete nechať spätnú väzbu.',
  'section.partSummary.feedbackSuccess': 'Ďakujeme vám za spätnú väzbu!',
  // 404
  'globals.404.title': '404 – Stránka nenájdená',
  'globals.404.intro': "Zatúlali ste sa na cestu, ktorá neexistuje...smola!",
  'globals.loading': 'Načítava sa...',
  // footer / menu
  'globals.menu': 'Menu',
  'globals.menu.about': 'O nás',
  'globals.menu.faq': 'Často kladené otázky',
  'globals.menu.privacyPolicy': 'Zásady ochrany osobných údajov',
  'globals.menu.termsofService': 'Podmienky služby',
  'globals.menu.myProfile': 'Môj profil',
  // Dashboard/Index
  'dashboard.title': 'Vitajte na kurze!',
  'dashboard.congratulations.title': 'Blahoželáme!',
  'dashboard.congratulations.message': 'Absolvovali ste kurz Elements of AI',
  'dashboard.congratulations.downloadCertificate': 'Stiahnuť certifikát',
  'dashboard.notLoggedIn.title': 'Vitajte!',
  'dashboard.notLoggedIn.message': 'Zaregistrujte sa alebo sa prihláste, aby ste získali úplný prístup ku kurzu. Je to zadarmo!',
  // Account
  'account.certificate': 'Certifikát',
  'account.myProfile': 'Môj profil',
  'account.profileInformation': 'Profilové informácie',
  'account.credits': 'Kredity ECTS',
  'account.changePassword': 'Zmeniť heslo',
  'account.changeLanguage': 'Zmeniť jazyk',
  'account.goToCourse': 'Choď na kurz',
  'account.buildingAINew': 'Nový kurz po anglicky',
  'account.accept': 'Prijmite',
  'account.decline': 'Odmietnite',
  'account.courses': 'Kurzy',
  // Sign Up page
  'signUp.description': 'Na vytvorenie nového účtu mooc.fi vyplňte tento formulár.',
  'signUp.email': 'Váš e-mail',
  'signUp.firstName': 'Vaše meno',
  'signUp.lastName': 'Vaše priezvisko',
  'signUp.postalCode': 'Poštové smerovacie číslo',
  'signUp.country': 'Kde žijete?',
  'signUp.Password': 'Heslo',
  'signUp.confirmPassword': 'Potvrdiť heslo',
  'signUp.howToStudyTitle': 'Akou formou sa chcete zúčastňovať na kurze?',
  'signUp.6weeksTitle': '6-týždňový kurz',
  'signUp.6weeksSubTitle': 'Odporúčané (približne 5h za týždeň)',
  'signUp.6weeksText': 'Určiť si konečný termín na ukončenie je osvedčený spôsob, ako kurz úspešne absolvovať.',
  'signUp.selfPacedTitle': 'Vlastné tempo',
  'signUp.selfPacedText': 'Na kurze sa môžete zúčastňovať vlastným tempom.',
  'signUp.ectsTitle': 'Uchádzate sa o kredity ECTS?',
  'signUp.ectsTitleSwe': ' ',
  'signUp.ectsStudent': 'Študujem na Helsinskej univerzite. Označte toto políčko a vyplňte svoje číslo študenta, aby ste automaticky získali kredity ECTS.s',
  'signUp.ectsStudentSwe': ' ',
  'signup.ectsStudentSweLink': ' ',
  'signUp.ectsOpenUni': 'Označte toto políčko, ak chcete dostať e-mail s pokynmi, ako požiadať o kredity ECTS',
  'signUp.studentNumber': 'Číslo študenta Helsinskej univerzity (ak na nej študujete)',
  'signUp.privacyOptIn': 'Súhlasím s použitím zhromaždených údajov o svojom študijnom správaní na výskumné účely. Údaje obsahujú informácie na základe používania materiálov a cvičení kurzu. Na základe uverejnených informácií nie je možné identifikovať jednotlivca.',
  'signUp.marketingOptIn': 'Súhlasím so zasielaním aktualizovaných informácií o pripravovaných jazykových verziách a informáciách týkajúcich sa nových kurzov. Súhlasím, že budem zdieľať svoje kontaktné informácie, aby som mohol dostávať správy šité na mieru na platformách tretích strán. Prečítajte si viac v naše zásady ochrany osobných údajov.',
  'signUp.acceptPolicyFirst': 'Prijímam zásady ochrany osobných údajov a zmluvné podmienky.',
  'signUp.privacyLink': 'Prečítajte si naše zásady ochrany osobných údajov',
  'signUp.error': 'Vo formulári sú chyby. Skontrolujte si formulár.',
  // Sign in page
  'signIn.title': 'Prihlásiť sa',
  'signIn.intro': 'Na prihlásenie so svojím účtom mooc.fi použite formulár nižšie.',
  'signIn.email': 'Váš e-mail',
  'signIn.password': 'Vaše heslo',
  'signIn.noAccount': "Nemáte účet?",
  'signIn.signUpNow': 'Zaregistrujte sa teraz',
  // Forgot password
  'pwForget.title': 'Zabudli ste heslo?',
  'pwForget.description': 'Vyplňte e-mail, ktorý ste použili na registráciu účtu na mooc.fi, aby sme vám mohli poslať odkaz na obnovenie hesla.',
  'pwForget.sendResetLink': 'Poslať odkaz na obnovenie hesla',
  'pwForget.completedDescription': 'Čoskoro by ste mali dostať e-mail z mooc.fi s odkazom na obnovenie hesla. Ak e-mail nevidíte, skontrolujte si spamový priečinok.',
  'pwForget.problemDescription': 'V prípade akýchkoľvek problémov napíšte na mooc@cs.helsinki.fi.',
  'pwForget.resetEmailSent': 'E-mail s odkazom na obnovenie hesla odoslaný',
  'pwForget.emailPlaceholder': 'E-mailová adresa',
  // password change
  'pwChange.authError': 'Autentifikácia zlyhala. Skúste sa prihlásiť znovu',
  'pwChange.unexpError': 'Heslo sa neaktualizovalo z dôvodu neočakávanej chyby',
  'pwChange.required': 'Povinné',
  'pwChange.pwMustMatch': 'Heslá sa musia zhodovať',
  'pwChange.currentPassword': 'Súčasné heslo',
  'pwChange.newPassword': 'Nové heslo',
  'pwChange.confirmPassword': 'Potvrdiť nové heslo',
  'pwChange.changingPassword': 'Heslo sa mení...',
  'pwChange.changePassword': 'Zmeniť heslo',
  'pwChange.passwordChanged': 'Heslo bolo zmenené',
  // user details
  'userDetails.authError': 'Autentifikácia zlyhala. Skúste sa prihlásiť znovu',
  'userDetails.unexpError': 'Profil sa neaktualizoval z dôvodu neočakávanej chyby',
  'userDetails.required': 'Povinné',
  'userDetails.invalidEmail': 'Neplatná e-mailová adresa',
  'userDetails.email': 'E-mail',
  'userDetails.emailAddress': 'E-mailová adresa',
  'userDetails.firstName': 'Meno',
  'userDetails.lastName': 'Priezvisko',
  'userDetails.language': 'Jazyk',
  'userDetails.updatingProfile': 'Profil sa aktualizuje...',
  'userDetails.updateProfile': 'Aktualizovať profil',
  'userDetails.profileUpdated': 'Profil aktualizovaný',
  // languages
  'languages.en.long': 'angličtina',
  'languages.fi.long': 'fínčina',
  'languages.se.long': 'svédčina',
  'languages.de.long': 'nemčina (Nemecko)',
  'languages.ee.long': 'estónčina',
  'languages.sk.long': 'slovenčina',
  'languages.it.long': 'taliančina',
  'languages.fr.long': 'francúzština (Francúzsko)',
  'languages.no.long': 'nórčina',
  'languages.lv.long': 'lotyština',
  'languages.pl.long': 'poľština',
  'languages.en-ie.long': 'angličtina (Írsko)',
  'languages.mt.long': 'maltčina',
  'languages.fr-be.long': 'francúzština (Belgicko)',
  'languages.lt.long': 'litovčina',
  'languages.nl-be.long': 'holandčina (Belgicko)',
  'languages.hr.long': 'chorvátčina',
  'languages.da.long': 'dánčina',
  'languages.ro.long': 'rumunčina',
  'languages.en-lu.long': 'angličtina (Luxembursko)',
  'languages.bg.long': 'bulharčina',
  'languages.cs.long': 'čeština',
  'languages.pt.long': 'portugalčina',
  'languages.nl.long': 'holandčina (Holandsko)',
  'languages.es.long': 'španielčina',
  'languages.de-at.long': 'nemčina (Rakúsko)',
  'languages.el.long': 'gréčtina',
  'languages.ga.long': 'írčina',
  'languages.sl.long': 'slovinčina',
  'languages.is.long': 'islandčina',
  // Email verfification
  'emailVerification.completedMessage': 'Vaša e-mailová adresa bola overená.',
  'emailVerification.verified': 'Overené',
  // Exercises
  'exercise.loading': 'Načítava sa cvičenie...',
  'exercise.signUp': 'Zaregistrujte sa, aby ste mohli vypĺňať cvičenia:',
  'exercise.score': 'Vaše skóre:',
  'exercise.wrongLocale': 'Chybné nastavenia locale',
  // Part tempplate
  'partTemplate.intro1': 'Kapitola ',
  'partTemplate.intro2': '  obsahuje tieto oddiely. Kliknite nižšie a začnite:',
  'partTemplate.spectrumPart1': 'Zapojte sa do ',
  'partTemplate.spectrum': 'komunity Elements of AI',
  'partTemplate.spectrumPart2': '. Tam môžete diskutovať a klásť otázky o tejto kapitole.',
  // missing info
  'missingInfo.title': 'Chýbajúce informácie',
  'missingInfo.body': 'Tieto otázky sa zvyčajne pýtame vtedy, keď sa používatelia registrujú, ale vy ste sa prihlásili s už existujúcim účtom mooc.fi. Aby ste mohli pokračovať, vyplňte prosím tento formulár.',
  'missingInfo.privacyPolicy': 'Prečítajte si naše zásady ochrany osobných údajov',
  'missingInfo.error': 'Vo formulári sú chyby. Skontrolujte si formulár.',
  'missingInfo.privacyPolicyUpdateBody': "We've updated our privacy policy. Here is a summary of the changes. We advertise our courses to potential new students by forming audiences in marketing platforms. This may involve sharing email addresses with these platforms. For the same purpose, anonymised activity data will be shared with marketing networks. You can prevent the sharing of email information by unchecking the marketing consent checkbox in your course profile. To prevent the sharing of activity data, you can change the cookie settings on the course site. Accepting these terms is required to continue using the service.",
  'missingInfo.privacyPolicyUpdateLink': 'Tu si môžete prečítať pravidlá ochrany osobných údajov.',
  // routes
  'routes.LANDING': '/sk/',
  'routes.SIGN_UP': '/sk/signup',
  'routes.SIGN_IN': '/sk/signin',
  'routes.MISSING_INFO': '/sk/missinginfo',
  'routes.PASSWORD_FORGET': '/sk/pw-forget',
  'routes.HOME': '/sk/home',
  'routes.ACCOUNT': '/sk/account',
  'routes.ACCEPT_POLICY': '/sk/accept-policy',
  'routes.PRIVACY': 'https://www.elementsofai.com/sk/faq/ochrany-súkromia',
  'routes.FAQ': 'https://www.elementsofai.com/sk/faq',
  'routes.ABOUT': 'https://www.elementsofai.com/sk',
  'routes.SPECTRUM': 'https://community.elementsofai.com/c/sk-slovenska-komunita',
  'routes.ects': 'https://mooc.fi/en/register-completion/elements-of-ai',
  'routes.ects.faq': 'https://www.elementsofai.com/sk/faq/how-can-i-get-2-ects-credits',
  'routes.TERMS': 'https://www.elementsofai.com/terms-of-service',
  'routes.BUILDING_AI': 'https://buildingai.elementsofai.com',
  // MarkdownComponents
  'markdowncomponents.linearRegressionStatic.xLabel': 'Počet šálok kávy za deň',
  'markdowncomponents.linearRegressionStatic.yLabel': 'Počet riadkov napísaného kódu',
  // meta
  'meta.title': 'Bezplatný online kurz',
  'meta.titleTemplate': '%s - Elements of AI',
  'meta.description': 'Zistite viac o kurze Helsinskej univerzity a skupiny Reaktor pre študentov a profesionálov, na ktorý netreba znalosti programovania ani matematiky.',
  'meta.sitename': 'Elements of AI',
  // ECTS
  'ects.text': 'Na tomto mieste po absolvovaní kurzu dostanete pokyny, ako požiadať o kredity ECTS.',
  'ects.textCompleted': 'Ak máte fínske číslo sociálneho zabezpečenia, môžete kliknúť sem a požiadať o kredity. ',
  'ects.link': 'tu.',
  'ects.info': 'Chcete sa uchádzať o kredity ECTS?',
  'ects.readMore': 'Čítaj viac',
  // Countries
  'countryselect.select': "Prosím vyber",
  'Finland': "Fínsko",
  'Estonia': "Estónsko",
  'Germany': "Nemecko",
  'Sweden': "Švédsko",
  "Countries": {
    "Afganistan": "Afganistan",
    "Åland Islands": "Alandské ostrovy",
    "Albania": "Albánsko",
    "Algeria": "Alžírsko",
    "American Samoa": "Americká Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarctica",
    "Antigua and Barbuda": "Antigua a Barbuda",
    "Argentina": "Argentína",
    "Armenia": "Arménsko",
    "Aruba": "Aruba",
    "Australia": "Austrália",
    "Austria": "Rakúsko",
    "Azerbaijan": "Azerbajdžan",
    "Bahamas": "Bahamské ostrovy",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladéš",
    "Barbados": "Barbados",
    "Belarus": "Bielorusko",
    "Belgium": "Belgicko",
    "Belize": "belize",
    "Benin": "Benin",
    "Bermuda": "Bermudy",
    "Bhutan": "Bhután",
    "Bolivia": "Bolívia",
    "Bosnia and Herzegovina": "Bosna a Hercegovina",
    "Botswana": "Botswana",
    "Bouvet Island": "Bouvetský ostrov",
    "Brazil": "Brazília",
    "British Indian Ocean Territory": "Britské indickooceánske územie",
    "Brunei Darussalam": "Brunej Darussalam",
    "Bulgaria": "Bulharsko",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodža",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cape Verde": "Cape Verde",
    "Cayman Islands": "Kajmanské ostrovy",
    "Central African Republic": "Stredoafrická republika",
    "Chad": "Chad",
    "Chile": "Čile",
    "China": "Čína",
    "Christmas Island": "Vianočný ostrov",
    "Cocos (Keeling) Islands": "Kokosové ostrovy",
    "Colombia": "Kolumbia",
    "Comoros": "komory",
    "Congo": "Congo",
    "Congo, The Democratic Republic of The": "Konžská demokratická republika",
    "Cook Islands": "Cookove ostrovy",
    "Costa Rica": "Kostarika",
    "Cote D'ivoire": "Pobrežie Slonoviny",
    "Croatia": "Chorvátsko",
    "Cuba": "Kuba",
    "Cyprus": "Cyprus",
    "Czech Republic": "Česká republika",
    "Denmark": "Dánsko",
    "Djibouti": "Džibutsko",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominikánska republika",
    "Ecuador": "Ekvádor",
    "Egypt": "egypt",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Rovníková Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estónsko",
    "Ethiopia": "Etiópia",
    "Falkland Islands (Malvinas)": "Falklandské ostrovy (Malvinas)",
    "Faroe Islands": "Faerské ostrovy",
    "Fiji": "Fiji",
    "Finland": "Fínsko",
    "France": "Francúzsko",
    "French Guiana": "Francúzska Guiana",
    "French Polynesia": "Francúzska Polynézia",
    "French Southern Territories": "Francúzske južné územia",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germany": "Nemecko",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltár",
    "Greece": "Grécko",
    "Greenland": "Grónsko",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and Mcdonald Islands": "Počul ostrov a Mcdonaldove ostrovy",
    "Holy See (Vatican City State)": "Svätá stolica (Vatikánsky mestský štát)",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Maďarsko",
    "Iceland": "Island",
    "India": "India",
    "Indonesia": "Indonézia",
    "Iran, Islamic Republic of": "Irán, Islamská republika",
    "Iraq": "Irak",
    "Ireland": "Írsko",
    "Isle of Man": "Ostrov Man",
    "Israel": "Izrael",
    "Italy": "Taliansko",
    "Jamaica": "Jamaica",
    "Japan": "Japonsko",
    "Jersey": "Jersey",
    "Jordan": "Jordán",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Keňa",
    "Kiribati": "Kiribati",
    "Korea, Democratic People's Republic of": "Kórejská ľudovodemokratická republika",
    "Korea, Republic of": "Kórea, Republika",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuvajt",
    "Kyrgyzstan": "Kirgizsko",
    "Lao People's Democratic Republic": "Laoská ľudovodemokratická republika",
    "Latvia": "Lotyšsko",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Libéria",
    "Libyan Arab Jamahiriya": "Líbyjská arabská džamahírija",
    "Liechtenstein": "Lichtenštajnsko",
    "Lithuania": "Litva",
    "Luxembourg": "Luxembursko",
    "Macao": "Macao",
    "Macedonia, The Former Yugoslav Republic of": "Macedónsko, bývalá juhoslovanská republika",
    "Madagascar": "madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malajzia",
    "Maldives": "Maledivy",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Maršalove ostrovy",
    "Martinique": "Martinique",
    "Mauritania": "Mauritánia",
    "Mauritius": "Mauritius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexiko",
    "Micronesia, Federated States of": "Mikronézske federatívne štáty",
    "Moldova, Republic of": "Moldavská republika",
    "Monaco": "Monaco",
    "Mongolia": "Mongolsko",
    "Montenegro": "Čierna Hora",
    "Montserrat": "Montserrat",
    "Marocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Mjanmarsko",
    "Namibia": "Namíbia",
    "Nauru": "Nauru",
    "Nepal": "Nepál",
    "Netherlands": "Holandsko",
    "Netherlands Antilles": "Holandské Antily",
    "New Caledonia": "Nová Kaledónia",
    "New Zealand": "Nový Zéland",
    "Nicaragua": "Nikaragua",
    "Niger": "Niger",
    "Nigeria": "Nigéria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Island",
    "Northern Mariana Islands": "Severné Mariány",
    "Norway": "Nórsko",
    "Oman": "Omán",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestinian Territory, Occupied": "Palestínske územie, okupované",
    "Panama": "Panama",
    "Papua New Guinea": "Papua-Nová Guinea",
    "Paraguay": "Paraguaj",
    "Peru": "Peru",
    "Philippines": "Filipíny",
    "Pitcairn": "Pitcairn",
    "Poland": "Poľsko",
    "Portugal": "Portugalsko",
    "Puerto Rico": "Portoriko",
    "Qatar": "Katar",
    "Reunion": "Réunion",
    "Romania": "Rumunsko",
    "Russian Federation": "Ruská federácia",
    "Rwanda": "Rwanda",
    "Saint Helena": "Svätá Helena",
    "Saint Kitts and Nevis": "Svätý Krištof a Nevis",
    "Saint Lucia": "Svätá Lucia",
    "Saint Pierre and Miquelon": "Saint Pierre a Miquelon",
    "Saint Vincent and The Grenadines": "Svätý Vincent a Grenadíny",
    "Samoa": "Samoa",
    "San Marino": "San Maríno",
    "Sao Tome and Principe": "Svätý Tomáš a Princov ostrov",
    "Saudi Arabia": "Saudská Arábia",
    "Senegal": "Senegal",
    "Serbia": "Srbsko",
    "Seychelles": "Seychely",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Slovakia": "Slovensko",
    "Slovenia": "Slovinsko",
    "Solomon Islands": "Šalamúnove ostrovy",
    "Somalia": "Somálsko",
    "South Africa": "Južná Afrika",
    "South Georgia and The South Sandwich Islands": "Južná Georgia a Južné Sandwichove ostrovy",
    "Spain": "španielsko",
    "Sri Lanka": "Srí Lanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard a Jan Mayen",
    "Swaziland": "Svazijsko",
    "Sweden": "Švédsko",
    "Swizerland": "Swizerland",
    "Syrian Arab Republic": "Sýrska arabská republika",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tadžikistan",
    "Tanzania, United Republic of": "Tanzánia, Spojené kráľovstvo",
    "Thailand": "Thailand",
    "Timor-leste": "Východný Timor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad a Tobago",
    "Tunisia": "Tunisko",
    "Turkey": "Turecko",
    "Turkmenistan": "Turkménsko",
    "Turks and Caicos Islands": "Ostrovy Turks a Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukrajina",
    "United Arab Emirates": "Spojené Arabské Emiráty",
    "United Kingdom": "Spojene kralovstvo",
    "United States": "Spojené štáty",
    "United States Minor Outlying Islands": "Menšie odľahlé ostrovy Spojených štátov",
    "Uruguay": "Uruguaj",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands, British": "Panenské ostrovy, Briti",
    "Virgin Islands, U.S.": "Panenské ostrovy, USA",
    "Wallis and Futuna": "Wallis a Futuna",
    "Western Sahara": "Západná Sahara",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe"
  },
  //Alt texts
  'alt.example-1': 'dve autonómne autá',
  'alt.example-2': 'ruka držiaca mobilný telefón',
  'alt.example-3': "ženská tvár",
  'alt.defining-ai': 'spleť slov súvisiacich s umelou inteligenciou',
  'alt.turing-test': 'muž četujúci na počítači s robotom a so ženou',
  'alt.chinese-room': 'myšlienkový experiment čínskej izby',
  'alt.chicken-crossing': 'robot v člne dívajúci sa na sliepku, líšku a vrecko slepačieho krmiva',
  'alt.a-to-b': 'ruka držiaca telefón zobrazujúci navigačnú aplikáciu',
  'alt.tic-tac-toe': 'dve deti hrajúce piškvorky',
  'alt.self-driving-car': 'autonómne auto pri značke Stop',
  'alt.spam-or-ham': 'bežný e-mail a spamový e-mail  ',
  'alt.mnist': 'dátový súbor MNIST',
  'alt.nearest-neighbor': 'dvaja nakupujúci',
  'alt.nearest-neighbor-graph': 'klasifikátor najbližšieho suseda',
  'alt.recommendation': 'muž a žena počúvajúci hudbu na telefóne',
  'alt.price-real-estate': 'tri rôzne domy',
  'alt.supervised-learning': 'človek vyučujúci robota',
  'alt.dice': 'kocky',
  'alt.chess': 'šachovnica',
  'alt.poker': 'ruka držiaca hracie karty',
  'alt.eyes': 'tri hnedooké a jedna modrooká osoba',
  'alt.search-1': 'jedna cesta vedúca k cieľu',
  'alt.search-2': 'štyri rôzne cesty vedúce k rovnakému cieľu',
  'alt.chicken-crossing-1': 'sliepka a čln – počiatočný stav',
  'alt.chicken-crossing-2': 'sliepka a čln – prvý prechod',
  'alt.chicken-crossing-3': 'sliepka a čln – všetky prechody',
  'alt.chicken-crossing-4': 'sliepka a čln – najlepšia trasa',
  'alt.chicken-crossing-5': 'sliepka a čln – počiatočný stav',
  'alt.implication-1': 'muž a žena',
  'alt.implication-2': 'oči',
  'alt.implication-3': 'monitorovacia kamera',
  'alt.implication-4': 'robot pracujúci na laptope',
  'alt.oddchange': 'slnko a oblaky',
  'alt.brain': 'kresba mozgu',
  'alt.carstop': 'autonómne auto pri značke Stop',
  'alt.gan': 'robot držiaci obrázok mačky a obrázok značky Stop',
  'alt.hammock': 'robot ovievajúci muža v hojdacej sieti',
  'alt.game-tree-1': 'herný strom 1',
  'alt.game-tree-2': 'herný strom 2',
  'alt.game-tree-3': 'herný strom 3',
  'alt.game-tree-4': 'herný strom 4',
  'alt.bayes-rule-1': 'obrázok znázorňujúci falošne pozitívne a falošne negatívne výsledky pri skríningu rakoviny',
  'alt.bayes-rule-2': 'obrázok znázorňujúci falošne pozitívne a falošne negatívne výsledky pri skríningu rakoviny',
  'alt.defineai': 'spleť slov súvisiacich s umelou inteligenciou',
  'alt.winter': 'robot spiaci na snehu',
  'alt.terminator': 'strašidelný robot',
}
